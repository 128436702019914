import React, { useState } from 'react';
import { navigate } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Layout from '../../components/Layout';

import { downloadFile } from '../../utils/common';

import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';
import imgBlockR from '../../assets/images/download-brochure/BrexitGuide.jpg';
import imgDots from '../../assets/images/home-page/Dots.png';

import filePdf from '../../assets/files/USA guide 2022.pdf';

import '../../assets/styles/pages/brexit-guide-page.scss';

const EMPTY_FORM_DATA = {
  firstName: '',
  lastName: '',
  userEmail: '',
};

const BrexitGuide = () => {
  const [formData, setFormData] = useState(EMPTY_FORM_DATA);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const sourceLabel = 'USA Brochure';
    const MailchimpData = {
      FNAME: formData.firstName,
      LNAME: formData.lastName,
      MMERGE4: new Date().toISOString().split('T')[0], // contact date
      MMERGE17: sourceLabel, // Source
    };

    await fetch('/api/download-brochure', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formData,
        sourceLabel,
        userFromGoogleAds: window?.userFromGoogleAds,
        userFromFacebookAds: window?.userFromFacebookAds,
        userFromGoOverseas: window?.userFromGoOverseas,
        userFromGoAbroad: window?.userFromGoAbroad,
      }),
    });
    addToMailchimp(formData.userEmail, MailchimpData);
    downloadFile('USA guide 2022.pdf', filePdf);

    setFormData(EMPTY_FORM_DATA);

    navigate('/spanish-visa-guide-us-citizens/thank-you');
  };

  return (
    <Layout
      title="Spanish Student Visa Guide for U.S. Citizens | 2022"
      description="A detailed, step-by-step guide for U.S. citizens to apply for a student visa in Spain. Get your visa approved and start your adventure in Spain!"
    >
      <div className="brexit-guide-page">
        <section className="second-section">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-lg-6 col-md-7 order-2">
                <div className="block_l">
                  <h1 className="c-title-42">
                    Download your free Spanish visa guide for U.S. citizens!
                  </h1>
                  <p className="c-text-18">
                    Everything you need to know about getting a visa to teach in Spain, with our
                    step-by-step guide.
                  </p>
                  <form id="brexit" onSubmit={handleSubmit} className="form">
                    <h1 className="c-title-22 form__title">Get your free guide now</h1>

                    <label className="form__first-name">
                      <p className="c-text-14 text-under">First Name</p>
                      <input
                        className="input__first-name"
                        type="text"
                        placeholder="John"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <label className="form__last-name">
                      <p className="c-text-14 text-under">Last Name</p>
                      <input
                        className="input__last-name"
                        type="text"
                        placeholder="Smith"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </label>

                    <label className="form__first-name email">
                      <p className="c-text-14 text-under">Email</p>
                      <input
                        className="input__email"
                        type="email"
                        placeholder="johnsmith@gmail.com"
                        name="userEmail"
                        value={formData.userEmail}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <a href={filePdf}>
                      <button type="submit" className="form__btn c-btn c-btn--red">
                        Download visa guide
                      </button>
                    </a>
                  </form>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-5 order-1">
                <div className="block_r">
                  <div className="block_r__img">
                    <img src={imgBlockR} alt="" className="block_r__img" />
                  </div>

                  <div className="block__logo block_r__trinity">
                    <img src={imgDots} alt="iconTrinity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default BrexitGuide;
